




import { Component, Vue, Prop } from 'vue-property-decorator';
import RankingHistoryPopup from '../components/ranking-history.popup.vue';

@Component({
    components: { RankingHistoryPopup },
})
export default class RankingHistoryModal extends Vue {}
