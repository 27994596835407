






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
/* eslint-disable */
import ITooltipData from '../interfaces/tooltip-data.interface';
import TooltipNameFilter from '../filters/tooltip-name.filter';

@Component({
    filters: { TooltipNameFilter },
})
export default class RankingHistoryTooltip extends Vue {
    @Prop({
        type: Object,
        required: true,
    })
    data!: ITooltipData;

    @Prop({
        type: Boolean,
        default: false,
    })
    isTooltipFlipped!: boolean;

    @Prop({
        type: Number,
        default: -60
    })
    arrowXOffset!: -60 | -30 | -90;

    mounted() {
        this.$emit('showSecondYAxis'); 
    }

    beforeDestroy() {
        this.$emit('hideSecondYAxis');
    }
}
