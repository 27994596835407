import moment from 'moment';

export default function PopupDate(value: string | null) {
    if (value === null) {
        return '---';
    }

    const splittedDate = value.split('-').map(v => parseInt(v, 10));
    const date = new Date(splittedDate[2], splittedDate[1] - 1, splittedDate[0]);

    return moment(date).format('dddd MMMM D, YYYY');
}
